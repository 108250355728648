import React from "react";
import { Box, Typography } from "@mui/material";

import ImageFileSelectWrapper from "./image-file-select-wrapper";
import { AddPhotoAlternate } from "@mui/icons-material";


const DisplayNoImageSelected = () => {
  return (
    <ImageFileSelectWrapper
    >
      <Box
        height={{ xs: 350, md: 250 }}
        width={{ xs: "100%", md: 300 }}
        component="span"
        sx={{
          border: "1px dashed lightgrey",
          borderColor: (theme) => theme.palette.company.main,
          borderRadius: "8px",
          display: "grid",
          placeItems: "center",
          p: { xs: 2, md: 0 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AddPhotoAlternate
            fontSize="large"
            sx={{
              color: (theme) => theme.palette.company.main,
              fontSize: { xs: "2rem", md: "2.75rem" },
            }}
           />
          <Typography variant="body2" fontWeight={600}>
            Kliknite pre nahranie vášho obrázka.
          </Typography>
          {/* <Typography variant="body2">or drag and drop here</Typography> */}
        </Box>
      </Box>
    </ImageFileSelectWrapper>
  );
};

export default DisplayNoImageSelected;
