import { useState, SyntheticEvent } from "react";

import { Box, Card, Tab, Typography } from "@mui/material";

import DesktopTabs from "./desktopTabs";
import UserProfileDetailsTab from "./userProfileDetailsTab";
import HeaderCard from "./headerCard";
import DeleteAccountButton from "./deleteAccountButton";

import { getInitials } from "../../helpers/getInitials";

import { schemaType } from "../../models/profile";
import { IUserProfileDetailsTab, UserProfileProps } from "./types";
import MobileTabs from "./mobileTabs";
import { Euro, ShoppingBag } from "@mui/icons-material";

const tabs: IUserProfileDetailsTab[] = [
  {
    label: "Personal Details",
  },
  {
    label: "Company Details",
    isCompany: true,
  },
];

const UserProfile = (props: UserProfileProps) => {
  const { user, customer, totalOrders, totalSpent } = props;

  const _editableValues = {
    firstName: customer.firstName,
    lastName: customer.lastName,
    email: customer.email,
    phone: customer.phone,
    password: "",
    billingAddress: customer.billingAddress,
    useShippingAddress: customer.useShippingAddress,
    gdprConsent: customer.gdprConsent,
    marketingConsent: customer.marketingConsent,
    company: customer.company ?? null,

    // used for only UI,
    // removed before submit
    hasCompanyData: Boolean(customer.company.name),
  };

  const [tabValue, setTabValue] = useState(0);
  const [mobileTabOpen, setMobileTabOpen] = useState(false);
  const [editableValues, setEditableValues] = useState(_editableValues)

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const toggleMobileTab = () => setMobileTabOpen(prev => !prev);
  const handleMobileTabChange = (newValue: number) => {
    setTabValue(newValue);
    toggleMobileTab();
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          gap: 5,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: { xs: "60px", sm: "100px" },
              height: { xs: "60px", sm: "100px" },

              background: "#383637",
              borderRadius: "100%",
              display: "grid",
              placeItems: "center",
              textTransform: "capitalize",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "1.15rem", md: "1.45rem" },
                color: "white",
              }}
            >
              {getInitials(user.firstName)}
            </Typography>
          </Box>

          <Box>
            <Typography
              fontWeight={600}
              sx={{ fontSize: { xs: "1.15rem", md: "1.25rem" } }}
            >
              {user.firstName} {user.lastName}
            </Typography>

            <Typography sx={{ fontSize: { xs: "0.85rem", md: "initial" } }}>
              Account created: {new Date(customer.registerDate).toDateString()}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 5,
          }}
        >
          <HeaderCard value={totalSpent} icon={<Euro />} label="Celková minutá suma" />
          <HeaderCard
            value={totalOrders}
            icon={<ShoppingBag />}
            label="Celkový počet objednávok"
          />
        </Box>
      </Box>

      {/* Tabs */}
      <Card
        sx={(theme) => ({
          bgcolor: theme.palette.secondary.light,
          boxShadow: "none",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        })}
      >
        <DesktopTabs value={tabValue} onChange={handleTabChange}>
          {tabs.map(({ label, isCompany }) => {
            const isCompanyTab = isCompany ?? false;
            const hasCompanyAccount = Boolean(customer.company.name);
            const formCheckboxCompanyAccount = editableValues.hasCompanyData

            const disableTab = isCompanyTab && !hasCompanyAccount && !formCheckboxCompanyAccount;

            return (
              <Tab
                key={label}
                label={label}
                disableRipple
                disabled={disableTab}
              />
            );
          })}
        </DesktopTabs>

        <MobileTabs
          {...{
            tabs,
            tabValue,
            toggleMobileTab,
            mobileTabOpen,
            handleMobileTabChange,
            customer,
            editableValues
          }}
        />

        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <DeleteAccountButton />
        </Box>
      </Card>

      {/* Tab Content */}
      {tabs.map(({ label, isCompany }, index) => {
        const isCompanyTab = isCompany ?? false;

        if (tabValue !== index) return null;

        return (
          <div key={label} role="tabpanel" hidden={tabValue !== index}>
            <Card
              sx={(theme) => ({
                background: theme.palette.secondary.light,
                boxShadow: "none",
                borderRadius: "8px",
                padding: "30px",
              })}
            >
              <UserProfileDetailsTab
                isCompanyTab={isCompanyTab}

                // Ideally an object but state is fine
                // Because by default, formik initialValues cant be re-initialized
                initialValues={editableValues as unknown as schemaType}
                setEditableValues={setEditableValues}
              />
            </Card>

            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
                mt: "42px",
                justifyContent: "center",
              }}
            >
              <DeleteAccountButton />
            </Box>
          </div>
        );
      })}
    </>
  );
};

export default UserProfile;
