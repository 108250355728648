import * as React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  IconButton,
} from "@mui/material";

import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import { PaperDraggable } from "./components/paperDraggable";
import { Product } from "./product";
import { IProduct } from "../../../models/ecomm/product";
import { useEcomm } from "../../../context/ecomm-context";
import { useApp } from "../../../context/app-context";
import { calculatePPU } from "../../../helpers/ecomm/functions";
import { Add } from "@mui/icons-material";

interface IDialogProps {
  open: boolean;
  selectedValue: IProduct | null;
  onSelect: (product: IProduct | null) => void;
}

const ProductDialog = ({ open, onSelect, selectedValue }: IDialogProps) => {
  const { products } = useEcomm();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth="lg"
      onClose={() => onSelect(selectedValue)}
      aria-labelledby="draggable-color-dialog"
      fullWidth={true}
      PaperComponent={PaperDraggable}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        // id="draggable-color-dialog"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          Výber skla
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          {products &&
            products.map((product: IProduct, index) => (
              <Grid item xl={3} md={4} xs={12} key={index}>
                <Product product={product} onSelect={onSelect} />
              </Grid>
            ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

interface IProductSelect {
  params: GridRenderCellParams;
  readOnly?: boolean;
  id?: string;
}
export const ProductSelect = ({ params, id: attributeID, readOnly = false }: IProductSelect) => {
  const { id, value, field } = params;
  const { rows, updateAppContext } = useApp();

  const [open, setOpen] = React.useState<boolean>(false);
  const [productValue, setProductValue] = React.useState<IProduct | null>(
    value
  );
  const apiRef = useGridApiContext();

  const handleClickOpen = () => {
    if (readOnly) return;
    setOpen(true);
  };

  const handleProductSelect = (newProductValue: IProduct | null) => {
    if (newProductValue === null) return;

    setProductValue(newProductValue);

    apiRef.current.setEditCellValue({
      id,
      field,
      value: newProductValue,
    });

    // Update the table of orders when new product is selected
    // Update dimensions: width, height
    // Update pricing and related: ppm2, ppu, price
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        row.glassWidth = newProductValue.maxWidth;
        row.glassHeight = newProductValue.maxHeight;

        row.ppm2 = newProductValue.price;
        row.ppu = calculatePPU(
          row.glassWidth,
          row.glassHeight,
          row.ppm2
        );
        row.price = row.ppu * row.qty;
      }

      return row;
    });

    updateAppContext({
      rows: updatedRows
    })

    // close dialog
    setOpen(false);
  };

  const handleRef = (element: HTMLDivElement) => {
    if (element) {
      element.focus();
    }
  };

  return (
    <Box
      id={attributeID}
      component="div"
      ref={handleRef}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {productValue?.name && (
        <Typography
          variant="body2"
          onClick={handleClickOpen}
          sx={{ cursor: readOnly ? "default" : "pointer" }}
        >
          {productValue?.name}
        </Typography>
      )}

      {!productValue?.name && (
        <IconButton
          sx={{ cursor: readOnly ? "default" : "pointer" }}
          onClick={handleClickOpen}
          disabled={readOnly}
        >
          <Add />
        </IconButton>
      )}

      <ProductDialog
        selectedValue={productValue}
        open={open}
        onSelect={handleProductSelect}
      />
    </Box>
  );
};
