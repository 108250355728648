import React, { ChangeEvent, useState } from "react";

import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton, TextField,
  Typography
} from "@mui/material";

import { UserDataType } from "../../context/types";
import { Close } from "@mui/icons-material";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  user: UserDataType | null;
}

export const DeleteProfileDialog = (props: Props) => {
  const { open, handleClose, handleDelete, user } = props;
  const [fieldValue, setFieldValue] = useState("");

  const handleCloseDialog = () => {
    handleClose();
    setFieldValue("");
  };

  const handleDeleteAction = () => {
    handleDelete();
    setFieldValue("");
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.value);
  const fieldMatchProfile = () => {
    const cleanedValue = fieldValue.replace(/\s/g, "");

    if (cleanedValue.length > 0 && cleanedValue === user!.email) {
      return true;
    }

    return false;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogTitle>
        Delete Profile
        <IconButton
          size="small"
          onClick={handleCloseDialog}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography textAlign="center">
          Ste si istý/á, že chcete vymazať váš účet?
        </Typography>

        <Typography textAlign="center" variant="subtitle2" fontWeight={600}>
          Táto akcia nemôže byť vrátená späť.
        </Typography>

        <TextField
          size="small"
          label="Napíšte váš email do vášho profilu na potvrdenie."
          fullWidth
          value={fieldValue}
          onChange={handleFieldChange}
          sx={{
            mt: 0.5,
          }} />
      </DialogContent>

      <DialogActions>
        <Button
          size="large"
          type="submit"
          sx={{ mr: 2 }}
          color="error"
          variant="contained"
          onClick={handleDeleteAction}
          disabled={!fieldMatchProfile()}
        >
          Ano
        </Button>
        <Button
          size="large"
          color="primary"
          variant="outlined"
          onClick={handleCloseDialog}
        >
          Nie
        </Button>
      </DialogActions>
    </Dialog>
  );
};
