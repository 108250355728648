import { Grid } from "@mui/material";
import { Map } from "../comps/contact/map";
// import { Form } from "../comps/contact/form";
import { ContactInfo } from "../comps/contact/contactInfo";
import { PageContainer } from "../comps/pageContainer";
import { Call, LocationOn, Mail, Schedule } from "@mui/icons-material";

export const ContactScreen = () => {

  return (
    <PageContainer sx={{ my: 2 }}>
      <>
        <Map
          mapCenter={[48.14742600140538, 17.148146689492798]}
          markers={[
            {
              position: [48.14614606969133, 17.146666356660802],
              tooltip: "Variobel",
            },
          ]}
          style={{ width: "100%", height: "400px", marginBottom: "16px" }}
        />
        <Grid
          container
          direction={{ xs: "column", md: "row" }}
          sx={{ mt: 2 }}
          columnSpacing={2}
          rowSpacing={2}
        >
          {/* <Grid item xs={12} md={6}>
            <Form
              title="Pošlite nám správu"
              fields={[
                { name: "name", type: "text", placeholder: "Your Name" },
                { name: "email", type: "email", placeholder: "Your Email" },
                { name: "subject", type: "text", placeholder: "Subject" },
                {
                  name: "message",
                  type: "text",
                  placeholder: "Message",
                  multiline: true,
                  maxRows: 4,
                  rows: 2,
                },
              ]}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <ContactInfo
              title="Kontaktujte nás"
              description="Sme pre Vás k dispozícii telefonicky alebo emailom:"
              contactInfo={[
                {
                  title: "Adresa",
                  icon: <LocationOn sx={{ mr: 2, fontSize: "20px" }} />,
                  detail:
                    "VARIODOOR s.r.o., Stara Prievozska 1349/2, 821 09, Bratislava",
                },
                {
                  title: "Telefón",
                  icon: <Call sx={{ mr: 2, fontSize: "20px" }} />,
                  detail: "+421 948 997 914",
                },
                {
                  title: "Email",
                  icon: <Mail sx={{ mr: 2, fontSize: "20px" }} />,
                  detail: "info@variobel.sk",
                },
                {
                  title: "Otváracie hodiny",
                  icon: <Schedule sx={{ mr: 2, fontSize: "20px" }} />,
                  detail: "Po - Ne / 09:00 - 17:00",
                },
              ]}
            />
          </Grid>
        </Grid>
      </>
    </PageContainer>
  );
};
