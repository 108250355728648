import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import Joyride, {
  Step,
  STATUS,
  CallBackProps,
  ACTIONS,
  EVENTS,
} from "react-joyride";
import { useLocation } from "react-router-dom";
import { useApp } from "./app-context";
import useDemoScrollIntoView from "../hooks/useDemoScrollIntoView";

interface DemoState {
  run: boolean;
  steps: Step[];
  stepIndex: number;
}

interface Props {
  children: ReactNode;
}

const demoSteps: Step[] = [
  {
    content: <h4>Začnime našu cestu!</h4>,
    placement: "center",
    target: "body",
  },
  {
    content: <h4>Kliknutím zobrazíte produkty</h4>,
    target: "#second_demo_step",
    spotlightPadding: 20,
    spotlightClicks: true,

    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
  },
  {
    content: <h4>Vyberte produkt</h4>,
    target: "#third_demo_step",
    spotlightClicks: true,

    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
  },
  {
    content: <h4>Pridať novú položku</h4>,
    hideBackButton: true,
    spotlightPadding: 0,
    target: "#fourth_demo_step",
  },
  {
    content: <h4>Názov položky</h4>,
    spotlightPadding: 15,
    target: "#fifth_demo_step",
  },
  {
    content: <h4>Výběr iného produktu</h4>,
    target: "#sixth_demo_step",
  },
  {
    content: <h4>Nastavte požadovanú výšku a šírku</h4>,
    target: "#seventh_demo_step",
  },
  {
    content: <h4>Výber potlače - farby alebo tapeta</h4>,
    target: "#eighth_demo_step",
  },
  {
    content: <h4>Nastav počet kusov</h4>,
    target: "#ninth_demo_step",
  },
  {
    content: <h4>Kopírovať položku</h4>,
    target: "#tenth_demo_step",
  },
];

const initialValues = {
  run: false,
  steps: demoSteps,
  stepIndex: 0,
};

const LS_FIRST_TIME_STRING = "firstTime";

const checkVisit = () => {
  const isFirstTime = localStorage.getItem(LS_FIRST_TIME_STRING);

  if (isFirstTime === null) {
    localStorage.setItem(LS_FIRST_TIME_STRING, JSON.stringify(true))
  }
}

const DemoContext = createContext(initialValues);

export const DemoProvider = (props: Props) => {
  const { children } = props;

  const { pathname } = useLocation();
  const { rows } = useApp();

  checkVisit();
  const firstTimeVisit = JSON.parse(localStorage.getItem(LS_FIRST_TIME_STRING) as string);

  const [{ run, steps, stepIndex }, setDemoSteps] = useState<DemoState>({
    ...initialValues,
  });

  const handleDemoCallback = (data: CallBackProps) => {
    const { status, type, action, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

    if (finishedStatuses.includes(status)) {
      setDemoSteps({ run: false, steps: demoSteps, stepIndex });
    }

    // Update state to advance the tour
    // primarily stepIndex
    if (type === EVENTS.STEP_AFTER) {
      setDemoSteps({ run: true, steps: demoSteps, stepIndex: nextStepIndex });
    }

    // Not a first time user anymore
    if (action === 'stop' || action === 'skip' || status === 'finished') {
      localStorage.setItem(LS_FIRST_TIME_STRING, JSON.stringify(false))
    }
  };

  // Control the start of demo tour
  useEffect(() => {
    if (pathname === "/" && firstTimeVisit) {
      setDemoSteps({ run: true, steps: demoSteps, stepIndex });
    }
  }, [stepIndex, pathname, firstTimeVisit]);

  // Move to next step: products view
  // when user clicks on go-to products btn
  useEffect(() => {
    if (pathname === "/obchod" && stepIndex === 1) {
      const stepIndex = 2;
      const values = { steps: demoSteps, stepIndex }

      setDemoSteps({ ...values, run: false });

      // timeout to allow calculation of tooltip position on stepIndex change
      setTimeout(() => {
        setDemoSteps({ ...values, run: true });
      }, 500);
    }
  }, [stepIndex, pathname]);

  // Move to next step: product table
  // when user selects a product
  useEffect(() => {
    if (stepIndex === 2 && rows.length) {
      const stepIndex = 3;
      const values = { steps: demoSteps, stepIndex }

      setDemoSteps({ ...values, run: false });

      // // timeout to allow calculation of tooltip position on stepIndex change
      setTimeout(() => {
        window.scrollTo(0, 0);
        setDemoSteps({ ...values, run: true });
      }, 600);

    }
  }, [rows, stepIndex]);

  // Custom scrolling
  // Supports regular vertical scroll
  // Also accounts for missing horizontal scroll feature
  useDemoScrollIntoView(steps, stepIndex, run);

  return (
    <>
      <Joyride
        callback={handleDemoCallback}
        continuous
        hideCloseButton
        locale={{
          next: "Ďalšie",
          skip: "Preskočiť",
          back: "Späť"
        }}
        disableScrollParentFix

        // Disabled in favour of custom scrolling
        disableScrolling
        run={run}
        showProgress
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#f4a600",
            textColor: "#005486",
          },
          buttonNext: {
            color: "#002D47" // darker version of textColor, accessibility contrast in pagespeed
          }
        }}
        aria-label="tutorial-dialog"
      />
      {children}
    </>
  );
};

export const useDemoContext = () => useContext(DemoContext);
