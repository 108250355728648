import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Pagination, PaginationItem } from "@mui/material";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="secondary"
      shape="rounded"
      size="large"
      sx={{
        "& .MuiPagination-ul": {
          gap: "16px",
        },
      }}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          size="large"
          components={{
            previous: () => <ArrowLeft />,
            next: () => <ArrowRight />,
          }}
          sx={(theme) => ({
            borderRadius: "8px",
            border: "2px solid #D0D5DD",

            "&.Mui-selected": {
              color: "white",
              border: "none",
            },

            "&.MuiPaginationItem-previousNext": {
              border: "none",
              p: 0,
              minWidth: 0,
              color: theme.palette.secondary.main,

              "&.Mui-disabled": {
                color: "#383637",
              },
            },

            "& .MuiIcon-root": {
              fontSize: "2rem",
            },
          })}
        />
      )}
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};
