import React from "react";
import {
  Dialog, DialogContent,
  DialogTitle,
  Typography,
  IconButton} from "@mui/material";
import { IOrder } from "../../models/ecomm/order";
import OrderContent from "./orderContent";
import { HighlightOffOutlined } from "@mui/icons-material";


interface Props {
  open: boolean;
  handleClose: () => void;
  order: IOrder;
}

export const OrderSummaryDialog = (props: Props) => {
  const { open, handleClose, order } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle sx={{ p: { xs: "36px 36px 12px", md: "64px 64px 24px" } }}>
        <Typography
          color="secondary"
          sx={{ fontSize: { xs: "1rem", md: "1.25rem" } }}
        >
          Súhrn objednávky
        </Typography>

        <IconButton
          size="small"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: "1rem",
            top: "1rem",
            background: theme.palette.secondary.light,
          })}
        >
          <HighlightOffOutlined sx={{ xs: "1.25rem", md: "1.5rem" }} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: { xs: "36px", md: "64px" } }}>
        <OrderContent {...{ order }} />
      </DialogContent>
    </Dialog>
  );
};
