import { useState } from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  useTheme,
  Typography,
} from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";

interface IAccordionProps {
  heading: string;
  content: string;
}

const FaqAccordion = ({ heading, content }: IAccordionProps) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const handleChange =
    () => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded);
    };

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={handleChange()}
      TransitionProps={{}}
      disableGutters
      sx={{
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,

        boxShadow: "unset",

        "&:before": {
          display: "none",
        },

        borderRadius: "8px",

        "&.MuiAccordion-root:last-of-type": {
          borderRadius: "8px",
        },

        "&.MuiAccordion-root:first-of-type": {
          borderRadius: "8px",
        },

        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(180deg)",
        },
      }}
    >
      <MuiAccordionSummary
        expandIcon={
          <ArrowDownward
            sx={{ color: theme.palette.secondary.main, fontSize: "1.75rem" }}
          />
        }
        aria-controls={content}
        id={heading}
        sx={{
          borderRadius: expanded ? "8px 8px 0 0" : "8px",

          "&:hover": {
            background: "#003E5A33",
          },

          backgroundColor: expanded
            ? "#003E5A33"
            : theme.palette.secondary.light,

          height: { xs: "unset", md: 70 },
          px: 3,
          py: 1,
        }}
      >
        <Typography variant="body2" sx={{ fontSize: { md: "0.9rem" } }}>
          {heading}
        </Typography>
      </MuiAccordionSummary>

      <MuiAccordionDetails sx={{ py: 2, px: 3 }}>
        <Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
          {content}
        </Typography>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default FaqAccordion
