import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { IRowType } from "../../../models/ecomm/productTable";
import { ccyFormat } from "../../../helpers/ecomm/productFunctions";
import { useApp } from "../../../context/app-context";

import axios from "axios";
import { calculateCustomerDiscount, calculateCustomerDiscountAmount, calculateOnSaleDiscountValue, calculateOnSaleDiscountAmount } from "../../../helpers/ecomm/calculateDiscount";
import { useAuth } from "../../../hooks/useAuth";
import { onSaleDiscountType, onSaleDiscountTypeInterim } from "../../../models/ecomm/product";
import { ExpandCircleDown } from "@mui/icons-material";

export const ProductTableSummary = () => {
  const { user } = useAuth();
  const { rows, customer, updateAppContext } = useApp();

  const [tax, setTax] = React.useState<number>(0);

  const [customerDiscountAmount, setCustomerDiscountAmount] = React.useState(0);
  const [customerDiscount, setCustomerDiscount] = React.useState(0);

  const [onSaleDiscounts, setOnSaleDiscounts] = React.useState<
    onSaleDiscountTypeInterim[]
  >([]);
  const [totalOnSaleDiscount, setTotalOnSaleDiscount] = React.useState(0);

  const subtotal = React.useCallback((items: Array<IRowType>) => {
    return items
      .map((item: IRowType) => item.ppu * item.qty)
      .reduce((sum, i) => sum + i, 0);
  }, []);

  const [invoiceSubtotal, setInvoiceSubtotal] = React.useState(0);
  const [invoiceTaxes, setInvoiceTaxes] = React.useState(0);
  const [invoiceTotal, setInvoiceTotal] = React.useState(0);

  // update invoice subtotal
  React.useEffect(() => {
    const _invoiceSubtotal: number = subtotal(rows!);

    setInvoiceSubtotal(_invoiceSubtotal);
  }, [rows, subtotal]);

  // update invoice taxes
  React.useEffect(() => {
    const _invoiceTaxes: number = (tax / 100) * invoiceSubtotal;
    setInvoiceTaxes(_invoiceTaxes);
  }, [tax, invoiceSubtotal]);

  // update invoice total
  React.useEffect(() => {
    let _invoiceTotal: number = invoiceTaxes + invoiceSubtotal;
    _invoiceTotal = _invoiceTotal - totalOnSaleDiscount - customerDiscount;

    setInvoiceTotal(_invoiceTotal);
  }, [invoiceTaxes, invoiceSubtotal, totalOnSaleDiscount, customerDiscount]);

  // Get tax
  React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/company/get/${process.env.REACT_APP_COMPANY_ID}`,
        { withCredentials: true }
      )
      .then((res) => {
        const { company } = res.data;
        const _tax = company.taxRate;
        setTax(_tax);
      });
  }, []);

  // Calculate customer discount
  React.useEffect(() => {
    const _customerDiscountAmount = calculateCustomerDiscountAmount(
      customer,
      user
    );

    const _customerDiscount = calculateCustomerDiscount(
      _customerDiscountAmount,
      invoiceSubtotal,
      customer,
      user
    );

    setCustomerDiscountAmount(_customerDiscountAmount);
    setCustomerDiscount(_customerDiscount);
  }, [customer, invoiceSubtotal, user]);

  // Calculate on-sale discounts
  React.useEffect(() => {
    const _onSaleDiscounts: onSaleDiscountTypeInterim[] = [];

    rows.forEach((row) => {
      const { product, qty } = row;
      if (!product) return;

      if (product.onSale) {
        const discount = calculateOnSaleDiscountValue(product, customer, user);
        const amount = calculateOnSaleDiscountAmount(product, customer, user);

        _onSaleDiscounts.push({
          discount: discount * qty,
          amount,
          product,
          qty,
        });
      }
    });

    setOnSaleDiscounts(_onSaleDiscounts);
  }, [rows, customer, user]);

  // Calculate total on-sale discount
  React.useEffect(() => {
    if (!onSaleDiscounts.length) {
      setTotalOnSaleDiscount(0);
      return;
    }

    const totalDiscount = onSaleDiscounts.reduce((total, item) => {
      return total + item.discount;
    }, 0);

    setTotalOnSaleDiscount(totalDiscount);
  }, [onSaleDiscounts]);

  // Update pricing
  React.useEffect(() => {
    const onSale = onSaleDiscounts.map((item) => {
      const { product, ...rest } = item;

      return {
        ...rest,
        productID: product._id,
      } as onSaleDiscountType;
    });

    updateAppContext({
      basketPrice: {
        price: invoiceTotal,
        tax: invoiceTaxes,
        subtotal: invoiceSubtotal,
        taxRate: tax,
        qtyTotal: rows.reduce((total, row: IRowType) => {
          return total + row.qty;
        }, 0),

        appliedDiscounts: {
          onSale,

          customer: {
            value: customerDiscountAmount ?? 0,
            prefix: customer.discount.prefix ?? "%",
          },

          order: {
            value: 0,
            prefix: "%",
          },
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // updateAppContext, // infinite render
    rows,
    invoiceSubtotal,
    invoiceTaxes,
    invoiceTotal,
    tax,
    onSaleDiscounts,
    customerDiscountAmount,
    customer,
  ]);

  // TODO:
  // - add DPH price on product card
  // - add DPH to product table, not as column but on top of the price fields

  return (
    <Grid container justifyContent="flex-end" spacing={4}>
      <Grid item xs={12} md={5}>
        <TableContainer component={Paper}>
          <Table aria-label="spanning table">
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 0.25,

                      "& .MuiTypography-root": {
                        fontSize: "0.725rem",
                        color: "grey !important",
                      },
                    }}
                  >
                    <Typography fontSize="inherit">bez DPH</Typography>
                    <Typography fontSize="inherit">
                      {ccyFormat(invoiceSubtotal)} €
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography fontSize="inherit" textAlign="right">
                      Celkom DPH
                    </Typography>
                    <Typography fontSize="inherit">
                      {ccyFormat(invoiceSubtotal + invoiceTaxes)} €
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>

              {(onSaleDiscounts.length > 0 || customerDiscountAmount > 0) && (
                <TableRow>
                  <TableCell colSpan={3} sx={{ py: 0 }}>
                    <Accordion disableGutters sx={{ boxShadow: "none" }}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandCircleDown color="secondary" />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          p: 0,
                          "& .MuiAccordionSummary-content": {
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            mr: 1.5,
                          },

                          "& p": {
                            m: 0,
                          },
                        }}
                      >
                        <p>Zľavy</p>

                        <p style={{ color: "red" }}>
                          -{ccyFormat(totalOnSaleDiscount + customerDiscount)}€
                        </p>
                      </AccordionSummary>

                      <AccordionDetails sx={{ p: 0 }}>
                        {onSaleDiscounts.length > 0 && (
                          <>
                            {onSaleDiscounts.map((item, ind) => (
                              <Box
                                key={ind}
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  py: 2,
                                }}
                              >
                                <Typography fontSize="inherit">
                                  {item.product.name} <b>x {item.qty}</b>
                                </Typography>

                                <Typography
                                  fontSize="inherit"
                                  sx={{ color: "red" }}
                                >
                                  -{ccyFormat(item.discount)}€
                                </Typography>
                              </Box>
                            ))}
                          </>
                        )}

                        {customerDiscountAmount > 0 && (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              py: 2,
                            }}
                          >
                            <Typography fontSize="inherit">
                              Zákaznícka zľava{" "}
                              <b>
                                ({customerDiscountAmount}
                                {customer.discountPrefix})
                              </b>
                            </Typography>

                            <Typography
                              fontSize="inherit"
                              sx={{ color: "red" }}
                            >
                              -{ccyFormat(customerDiscount)}€
                            </Typography>
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                {/* <TableCell colSpan={2}>K uhrade</TableCell>
                <TableCell align="right">{ccyFormat(invoiceTotal)} €</TableCell> */}

                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                      mb: 0.25,

                      "& .MuiTypography-root": {
                        fontSize: "0.725rem",
                        color: "grey !important",
                      },
                    }}
                  >
                    <Typography fontSize="inherit">Celkom bez DPH</Typography>
                    <Typography fontSize="inherit">
                      {ccyFormat(
                        invoiceSubtotal -
                          (totalOnSaleDiscount + customerDiscount)
                      )}{" "}
                      €
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                      alignItems: "center",

                      "& .MuiTypography-root": {
                        color: (theme) => theme.palette.company.main,
                        fontWeight: 500
                      },
                    }}
                  >
                    <Typography fontSize="inherit" textAlign="right">
                      K uhrade
                    </Typography>
                    <Typography fontSize="inherit">
                      {ccyFormat(invoiceTotal)} €
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
