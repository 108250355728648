import { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ErrorMessage, Formik, FormikErrors, FormikHelpers } from "formik";
import { ConnectedFocusError } from 'focus-formik-error'
import * as Yup from "yup";
import { FormInput } from "../login/formInput";
import {
  Grid,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
  CircularProgress
} from "@mui/material";
import { ICustomerAddress, ICustomerForm } from "../../models/ecomm/customer";

// uncomment to access all countries
import /* countries,  */{ supportedCountries } from "../../helpers/ecomm/countries";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const CountrySchema = Yup.object().shape({
  code: Yup.string().required('Prosím zadajte krajinu'),
  label: Yup.string().required('Prosím zadajte krajinu'),
  phone: Yup.string().required('Prosím zadajte krajinu'),
});

export const AddressSchema = Yup.object().shape({
  company: Yup.string(),
  address: Yup.string().required("Prosím zadajte addresu"),
  city: Yup.string().required("Prosím zadajte mesto"),
  postcode: Yup.string().required("Prosím zadajte PSČ"),
  country: CountrySchema.required('Prosím zadajte krajinu'),
});

const companySchema = Yup.object().shape({
  name: Yup.string(),
  ico: Yup.string(),
  dic: Yup.string(),
  icdph: Yup.string(),
});

const SignUpFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Meno je povinný údaj")
    .max(40, "Meno ma príliž veľa znakov"),
  lastName: Yup.string()
    .required("Priezvisko je povinný úda")
    .max(40, "Priezvisko ma príliž veľa znakov"),
  email: Yup.string()
    .required("Email  je povinný údaj")
    .email("Zadaný email nie je platná emailová adresa"),
  password: Yup.string()
    .required("Heslo je povinný údaj")
    .max(100, "Heslo ma príliž veľa znakov")
    .min(6, "Heslo ma príliž málo znakov"),
  confirmpass: Yup.string()
    .oneOf([Yup.ref("password"), null], "Zadané heslá sa nezhodujú")
    .required("Prosím zadajte znova svoje heslo"),
  phone: Yup.string(),
  billingAddress: Yup.array().of(AddressSchema),
  gdprConsent: Yup.boolean()
    .required("Treba akceptovať podmienky spravovania osobných údajov")
    .test("isTrue", "Musíte súhlasiť", (val) => val === true),
  gdprConsentDate: Yup.date().required(),
  marketingConsent: Yup.boolean(),
  marketingConsentDate: Yup.date().required(),
  isRegistered: Yup.boolean().required(),

  isCompany: Yup.boolean(),
  company: companySchema
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmpass: "",
  phone: "",

  billingAddress: [{
    company: "",
    address: "",
    city: "",
    postcode: "",
    country: supportedCountries[0]
  }],

  gdprConsent: false,
  gdprConsentDate: new Date(),
  marketingConsent: true,
  marketingConsentDate: new Date(),

  isCompany: false,
  company: {
    name: "",
    ico: "",
    dic: "",
    icdph: ""
  },

  isRegistered: true
};

interface ISignUpFormProps {
  handleSubmit: (
    values: ICustomerForm,
    formikHelpers: FormikHelpers<ICustomerForm>
  ) => void;
}

export const SignUpForm = ({ handleSubmit }: ISignUpFormProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignUpFormSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <ConnectedFocusError />

          <Grid container>
            <Grid item xs={12} mb={1}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Dataily konta
              </Typography>
            </Grid>

            <>
              <Grid item xs={12}>
                <FormInput
                  id="email"
                  label="Emailová adresa"
                  fullWidth
                  name="email"
                  placeholder="Váš email"
                  value={values.email}
                  onChange={handleChange}
                  autoComplete="off"
                />

                {errors.email && (
                  <Box mb={2} color="red">
                    <ErrorMessage name="email" />
                  </Box>
                )}
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    id="password"
                    label="Heslo"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Vytvor heslo"
                    value={values.password}
                    onChange={handleChange}
                  />

                  {errors.password && (
                    <Box mb={2} color="red">
                      <ErrorMessage name="password" />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormInput
                    id="confirmpass"
                    label="Potvrdenie hesla"
                    fullWidth
                    type={showPassword ? "text" : "password"}                    autocomplete="new-password"
                    autoComplete="new-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Zadaj heslo znova"
                    value={values.confirmpass}
                    onChange={handleChange}
                  />

                  {errors.confirmpass && (
                    <Box mb={2} color="red">
                      <ErrorMessage name="confirmpass" />
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
              </Grid>
            </>

            <Grid item xs={12} mb={1}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Osobné údaje
              </Typography>
            </Grid>

            <>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    id="firstName"
                    label="Krstné meno"
                    fullWidth
                    name="firstName"
                    placeholder="Zadaj svoje krstné meno"
                    value={values.firstName}
                    onChange={handleChange}
                  />

                  {errors.firstName && (
                    <Box mb={2} color="red">
                      <ErrorMessage name="firstName" />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormInput
                    id="lastName"
                    label="Priezvisko"
                    fullWidth
                    name="lastName"
                    placeholder="Zadaj svoje priezvisko"
                    value={values.lastName}
                    onChange={handleChange}
                  />

                  {errors.lastName && (
                    <Box mb={2} color="red">
                      <ErrorMessage name="lastName" />
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  id="phone"
                  label="Telefónne čislo"
                  fullWidth
                  name="phone"
                  placeholder="Zadaj svoje telefónne číslo"
                  value={values.phone}
                  onChange={handleChange}
                />

                {errors.phone && (
                  <Box mb={2} color="red">
                    <ErrorMessage name="phone" />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} mb={2}>
                <Checkbox
                  name="isCompany"
                  checked={values.isCompany}
                  value={values.isCompany}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="caption" sx={{ fontWeight: 600 }}>
                  Is this a company account?
                </Typography>
              </Grid>

              {values.isCompany && (
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormInput
                      id="company.name"
                      label="Meno spoločnosti"
                      fullWidth
                      name="company.name"
                      placeholder="Zadajte názov spoločnosti"
                      value={values.company.name}
                      onChange={handleChange}
                    />

                    {errors.company?.name && (
                      <Box mb={2} color="red">
                        <ErrorMessage name="company.name" />
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormInput
                      id="company.ico"
                      label="IČO"
                      fullWidth
                      name="company.ico"
                      placeholder="Zadajte svoje IČO"
                      value={values.company.ico}
                      onChange={handleChange}
                    />

                    {errors.company?.ico && (
                      <Box mb={2} color="red">
                        <ErrorMessage name="company.ico" />
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormInput
                      id="company.dic"
                      label="DIČ"
                      fullWidth
                      name="company.dic"
                      placeholder="EZadajte svoje DIČ"
                      value={values.company.dic}
                      onChange={handleChange}
                    />

                    {errors.company?.dic && (
                      <Box mb={2} color="red">
                        <ErrorMessage name="company.dic" />
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormInput
                      id="company.icdph"
                      label="IČDPH"
                      fullWidth
                      name="company.icdph"
                      placeholder="Zadajte svoje IČDPH"
                      value={values.company.icdph}
                      onChange={handleChange}
                    />

                    {errors.company?.icdph && (
                      <Box mb={2} color="red">
                        <ErrorMessage name="company.icdph" />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
              </Grid>
            </>

            <Grid item xs={12} mb={1}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Doručovacia adresa
              </Typography>
            </Grid>

            <>
              <Grid item xs={12}>
                <FormInput
                  id="billingAddress[0].address"
                  label="Ulica"
                  fullWidth
                  name="billingAddress[0].address"
                  placeholder="Zadaj ulicu aj s popisovým číslom"
                  value={values.billingAddress[0].address}
                  onChange={handleChange}
                />

                {errors.billingAddress &&
                  (errors.billingAddress[0] as FormikErrors<ICustomerAddress>)
                    .address && (
                    <Box mb={2} color="red">
                      <ErrorMessage name="billingAddress[0].address" />
                    </Box>
                  )}
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    id="billingAddress[0].postcode"
                    label="PSČ"
                    fullWidth
                    type="text"
                    placeholder="Zadaj PSČ"
                    name="billingAddress[0].postcode"
                    value={values.billingAddress[0].postcode}
                    onChange={handleChange}
                  />

                  {errors.billingAddress &&
                    (errors.billingAddress[0] as FormikErrors<ICustomerAddress>)
                      .postcode && (
                      <Box mb={2} color="red">
                        <ErrorMessage name="billingAddress[0].postcode" />
                      </Box>
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormInput
                    id="billingAddress[0].city"
                    label="Mesto"
                    fullWidth
                    type="text"
                    placeholder="Zadaj mesto"
                    name="billingAddress[0].city"
                    value={values.billingAddress[0].city}
                    onChange={handleChange}
                  />

                  {errors.billingAddress &&
                    (errors.billingAddress[0] as FormikErrors<ICustomerAddress>)
                      .city && (
                      <Box mb={2} color="red">
                        <ErrorMessage name="billingAddress[0].city" />
                      </Box>
                    )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  id="billingAddress[0].country"
                  options={supportedCountries}
                  disabled
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  value={values.billingAddress[0].country}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  onChange={(e: object, value: any | null) => {
                    setFieldValue("billingAddress[0].country", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ mb: 1.25 }}
                      label="Krajina"
                      placeholder="Vyber krajinu"
                      name="billingAddress[0].country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

                {errors.billingAddress &&
                  (errors.billingAddress[0] as FormikErrors<ICustomerAddress>)
                    .country && (
                    <Box mb={2} color="red">
                      <ErrorMessage name="billingAddress.country[code]" />
                    </Box>
                  )}
              </Grid>
            </>
          </Grid>

          <Grid item xs={12}>
            <Checkbox
              name="gdprConsent"
              checked={values.gdprConsent}
              value={values.gdprConsent}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography variant="caption" sx={{ fontWeight: 600 }}>
              Súhlas so{" "}
            </Typography>

            <Typography
              variant="caption"
              component={Link}
              href="/"
              target="_blank"
              sx={{ fontWeight: 600 }}
            >
              spracúvaním osobných údajov
            </Typography>

            {errors.gdprConsent && (
              <Box mb={2} color="red">
                <ErrorMessage name="gdprConsent" />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} mb={2}>
            <Checkbox
              name="marketingConsent"
              checked={values.marketingConsent}
              value={values.marketingConsent}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography variant="caption" sx={{ fontWeight: 600 }}>
              Súhlas s posielaním marketingovej komunikácie
            </Typography>
          </Grid>

          <Box textAlign="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={
                isSubmitting ? (
                  <CircularProgress
                    style={{ color: "white", width: 22, height: 22 }}
                  />
                ) : null
              }
              sx={{
                mb: 2,
                borderRadius: "6px",
                fontSize: 16,
                fontWeight: "bold",
              }}
              disableElevation
            >
              Registrovať
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
