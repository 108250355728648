import * as React from "react";
import { IRowType, VariobelColor } from "../../../models/ecomm/productTable";
import { IBasketPrice } from "../../../models/ecomm/order";

import {
  Divider,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import { useApp } from "../../../context/app-context";
import { calculateCustomerDiscount } from "../../../helpers/ecomm/calculateDiscount";
import { useAuth } from "../../../hooks/useAuth";
import { ccyFormat } from "../../../helpers/ecomm/productFunctions";
import { ExpandCircleDown } from "@mui/icons-material";

interface IOrderItemsSummaryAlt {
  options?: {
    summaryTextVariant:
      | "subtitle1"
      | "button"
      | "caption"
      | "h1"
      | "h2"
      | "h3"
      | "h4"
      | "h5"
      | "h6"
      | "inherit"
      | "subtitle2"
      | "body1"
      | "body2"
      | "overline"
      | undefined;
  };
  rowsProps?: IRowType[];
  basketProps?: IBasketPrice;
}

const ThumbImage = ({ row }: { row: IRowType }) => {
  const ownWallpaperThumb = () => {
    if (row.print.type !== "ownWallpaper") return;

    const obj = JSON.parse(row.print.value as string);

    if (Object.hasOwn(obj, "cropped")) {
      return obj.cropped;
    }

    return obj.original;
  };

  const wallpaperThumb = () => {
    if (row.print.type !== "wallpaper") return "";

    const value = row.print.value as any;

    const obj = JSON.parse(value as string);
    return obj.file;
  };

  if (row.print.type === "color") {
    return (
      <Box
        sx={{
          borderRadius: "5px",
          width: { xs: 32, md: 60 },
          height: { xs: 32, md: 60 },
          backgroundColor: (row.print.value as VariobelColor).hex,
        }}
      />
    );
  }

  if (row.print.type === "ownWallpaper") {
    return (
      <Box
        component="img"
        src={ownWallpaperThumb()}
        sx={{
          borderRadius: "5px",
          width: { xs: 32, md: 60 },
          height: { xs: 32, md: 60 },
        }}
      />
    );
  }

  // local wallpaper
  return (
    <Box
      component="img"
      src={wallpaperThumb()}
      sx={{
        borderRadius: "5px",
        width: { xs: 32, md: 60 },
        height: { xs: 32, md: 60 },
      }}
    />
  );
};

const LineItem = ({ row, basket }: { row: IRowType; basket: IBasketPrice }) => {
  let isWallpaperSpread = false;

  const getIsWallpaperSpread = () => {
    if (row.print.type === "color") return;

    const value = row.print.value as any;

    // TODO: refactor, so admin and shop types are consistent
    const obj = JSON.parse(value as string);
    if (Object.hasOwn(obj, "isWallpaperSpread")) {
      isWallpaperSpread = obj.isWallpaperSpread;
    }
  };

  getIsWallpaperSpread();

  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", gap: { xs: 1, md: 2 } }}
      >
        <ThumbImage row={row} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: { xs: 0, md: 0.5 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "0.55rem", md: "0.9rem" },
                  fontStyle: "italic",
                }}
              >
                {row.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "0.75rem", md: "1rem" },
                  fontWeight: 600,
                }}
              >
                {`${row.product!.name} x ${row.qty}`}
                {isWallpaperSpread ? " (Spread)" : ""}
              </Typography>
            </Box>

            <Typography
              color="secondary"
              sx={{ fontSize: { xs: "0.85rem", md: "1rem" } }}
            >
              {(
                ((row.glassWidth * row.glassHeight) / 1000000) *
                row.qty
              ).toFixed(2)}{" "}
              m<sup>2</sup>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 0.25,
            }}
          >
            <Typography sx={{ fontSize: { xs: "0.7rem", md: "1rem" } }}>
              {`${row.glassWidth} x ${row.glassHeight} mm`}
            </Typography>

            <Typography
              color="secondary"
              sx={{ fontSize: { xs: "0.7rem", md: "1rem" } }}
            >
              {`${(
                Number(row.price) +
                (Number(row.price) * basket.taxRate) / 100
              ).toFixed(2)}
              €`}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ borderColor: "rgba(198, 210, 216, 0.42)" }} />
    </>
  );
};

export const OrderItemsSummaryAlt = ({
  options = { summaryTextVariant: "subtitle1" },
  rowsProps,
  basketProps,
}: IOrderItemsSummaryAlt) => {
  const { rows: rowsContext, basketPrice, customer } = useApp();
  const { user } = useAuth();

  const rows = rowsProps ?? rowsContext;
  const basket = basketProps ?? basketPrice;

  const [totalOnSaleDiscount, setTotalOnSaleDiscount] = React.useState(0);
  const [customerDiscount, setCustomerDiscount] = React.useState(0);

  // Calculate total on-sale discount
  React.useEffect(() => {
    if (!basket.appliedDiscounts) return;

    const onSaleDiscounts = basket.appliedDiscounts.onSale;

    if (!onSaleDiscounts.length) {
      setTotalOnSaleDiscount(0);
      return;
    }

    const totalDiscount = onSaleDiscounts.reduce((total, item) => {
      return Number(total + item.discount);
    }, 0);

    setTotalOnSaleDiscount(totalDiscount);
  }, [basket]);

  // Calculate customer discount
  React.useEffect(() => {
    if (!basket.appliedDiscounts) return;


    const _customerDiscount = calculateCustomerDiscount(
      basket.appliedDiscounts.customer.value,
      basket.subtotal,
      customer,
      user
    );

    setCustomerDiscount(_customerDiscount);
  }, [
    basket,
    customer,
    user,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {rows.map((row: IRowType, key) => {
        return <LineItem key={key} row={row} basket={basket} />;
      })}

      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            opacity: 0.7,
          }}
        >
          <Typography sx={{ fontSize: { xs: "0.45rem", md: "0.75rem" } }}>
            Celkom bez DPH
          </Typography>

          <Typography
            color="secondary"
            sx={{ fontSize: { xs: "0.45rem", md: "0.75rem" } }}
          >
            {basket.subtotal.toFixed(2)} €
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: { xs: "0.75rem", md: "1rem" } }}>
            Celkom DPH
          </Typography>

          <Typography
            color="secondary"
            sx={{ fontSize: { xs: "0.75rem", md: "1rem" } }}
          >
            {Number(basket.subtotal + basket.tax).toFixed(2)} €
          </Typography>
        </Box>
      </Box>

      {/* Will Order sdiscount be added? */}
      {basket.appliedDiscounts &&
        (basket.appliedDiscounts.onSale.length > 0 ||
          basket.appliedDiscounts.customer.value > 0) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Accordion
              disableGutters
              sx={{ boxShadow: "none", width: "100%", mt: -0.75 }}
            >
              <AccordionSummary
                expandIcon={<ExpandCircleDown color="secondary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  p: 0,
                  minHeight: 0,

                  "& .MuiAccordionSummary-content": {
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    mr: 1.5,
                    my: 1,
                  },
                }}
              >
                <Typography sx={{ fontSize: { xs: "0.75rem", md: "1rem" } }}>
                  Zľavy
                </Typography>

                <Typography
                  sx={{ fontSize: { xs: "0.75rem", md: "1rem" } }}
                  style={{ color: "red" }}
                >
                  -{ccyFormat(Number(totalOnSaleDiscount + customerDiscount))} €
                </Typography>
              </AccordionSummary>

              <AccordionDetails
                sx={{
                  p: 0,
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 1.75 }}
                >
                  {basket.appliedDiscounts.onSale.length > 0 && (
                    <>
                      {basket.appliedDiscounts.onSale.map((item, ind) => {
                        const currentRow = rows.find(
                          (row) => row.product?._id === item.productID
                        );

                        if (!currentRow) return null;

                        const product = currentRow.product;
                        return (
                          <Box
                            key={ind}
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: { xs: "0.75rem", md: "1rem" } }}
                            >
                              {product ? product.name : "Zľavový predaj"}{" "}
                              <b>x {item.qty}</b>
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: { xs: "0.75rem", md: "1rem" },
                                color: "red",
                              }}
                            >
                              -{ccyFormat(Number(item.discount))} €
                            </Typography>
                          </Box>
                        );
                      })}
                    </>
                  )}

                  {basket.appliedDiscounts.customer.value > 0 && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: { xs: "0.75rem", md: "1rem" } }}
                      >
                        Zákaznícka zľava{" "}
                        <b>
                          (
                          {`${basket.appliedDiscounts.customer.value} ${basket.appliedDiscounts.customer.prefix}`}
                          )
                        </b>
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: { xs: "0.75rem", md: "1rem" },
                          color: "red",
                        }}
                      >
                        -{ccyFormat(customerDiscount)} €
                      </Typography>
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}

      <Divider sx={{ borderColor: "rgba(198, 210, 216, 0.42)" }} />

      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            opacity: 0.7,
          }}
        >
          <Typography sx={{ fontSize: { xs: "0.45rem", md: "0.75rem" } }}>
            Celkom bez DPH
          </Typography>

          <Typography
            color="secondary"
            sx={{ fontSize: { xs: "0.45rem", md: "0.75rem" } }}
          >
            {(basket.subtotal - (totalOnSaleDiscount + customerDiscount)).toFixed(2)} €
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: { xs: "0.75rem", md: "1rem" } }}>
            Celkom k úhrade
          </Typography>

          <Typography color="secondary" sx={{ fontSize: { xs: "1rem" } }}>
            {basket.price.toFixed(2)}
            <span style={{ color: "#F4A600" }}>€</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
