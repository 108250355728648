import React from "react";
import {
  styled,
  Checkbox,
  Box,
  Typography,
  Slider,
  Button,
  FormGroup,
  FormControlLabel,
  Alert,
  alpha,
  IconButton,
} from "@mui/material";

import { TransformInputs } from "./wallpaper-transforms-inputs";
import { IsOrderItemDimensionsErrorType } from "./wallpaper-select-base/types";
import { Add, Error, Flip, Remove, RotateLeft, RotateRight, ZoomIn, ZoomOut } from "@mui/icons-material";

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: "0px",
}));

const marks = Array.from({ length: 7 }).map((val, i) => {
  return {
    value: i + 2,
    label: String(i + 2),
  };
});

interface Props {
  zoom?: number;
  handleZoom: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void;
  flipHorizontal: () => void;
  flipVertical: () => void;
  rotateLeft: () => void;
  rotateRight: () => void;
  spreadWallpaper: boolean;
  handleSpreadWallaperChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  previewWallpaperSpread: boolean;
  handlePreviewWallpaperSpreadChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleDoorsSpreadSlider: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void;
  orderItemDimensions: {
    width: number;
    height: number;
  };
  isOrderItemDimensionsError: IsOrderItemDimensionsErrorType;
  handleOrderItemDimensionsChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  doorsToSpread: number;
}

const WallpaperTransforms = (props: Props) => {
  const {
    zoom,
    handleZoom,
    flipHorizontal,
    flipVertical,
    rotateLeft,
    rotateRight,
    spreadWallpaper,
    handleSpreadWallaperChange,
    previewWallpaperSpread,
    handlePreviewWallpaperSpreadChange,
    handleDoorsSpreadSlider,
    orderItemDimensions,
    isOrderItemDimensionsError,
    handleOrderItemDimensionsChange,
    doorsToSpread,
  } = props;

  const minZoom = 1;
  const maxZoom = 3;
  const stepZoom = 0.1;

  const handleIncrement = (isInc: boolean) => {
    if (!zoom) return;
    const dummyEvent = {} as Event;
    const roundNumber = (num: number) => Number((num).toFixed(1));

    if (isInc) {
      const newValue = roundNumber(zoom + stepZoom);
      return handleZoom(dummyEvent, newValue, -1);
    }

    const newValue = roundNumber(zoom - stepZoom);
    return handleZoom(dummyEvent, newValue, -1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
          }}
        >
          <TransformInputs
            label="šírka"
            name="width"
            value={orderItemDimensions.width}
            onChange={handleOrderItemDimensionsChange}
            error={isOrderItemDimensionsError.width.value}
          />

          <TransformInputs
            label="Výška"
            name="height"
            value={orderItemDimensions.height}
            onChange={handleOrderItemDimensionsChange}
            error={isOrderItemDimensionsError.height.value}
          />
        </Box>

        {Object.entries(isOrderItemDimensionsError).map(
          ([dimension, { value, msg }]) =>
            value && (
              <Alert
                key={dimension}
                variant="filled"
                severity="error"
                icon={<Error />}
                sx={{
                  py: 0,
                  backgroundColor: (theme) =>
                    alpha(theme.palette.error.main, 0.15),
                  color: (theme) => theme.palette.error.main,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {msg}
              </Alert>
            )
        )}
      </Box>

      {spreadWallpaper && (
        <Box>
          <Typography
            id="spread-marker"
            variant="subtitle2"
            component="p"
            fontWeight={600}
            mb={0.5}
          >
            No. of Doors
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: 1.25,
              alignItems: "center",
            }}
          >
            <IconButton disableRipple sx={{ color: "unset" }}>
              <Remove />
            </IconButton>

            <Slider
              size="small"
              min={2}
              max={8}
              step={1}
              marks={marks}
              value={doorsToSpread}
              onChange={handleDoorsSpreadSlider}
              disabled={!spreadWallpaper}
              aria-labelledby="spread-marker"
              sx={{
                "& .MuiSlider-rail": {
                  backgroundColor: "#D9D9D9",
                  height: "4px",
                },

                marginBottom: "12px",

                "& .MuiSlider-markLabel": {
                  fontSize: "small",
                  top: 24,
                },

                "& .MuiSlider-mark": {
                  display: "none",
                },
              }}
            />

            <IconButton disableRipple sx={{ color: "unset" }}>
              <Add />
            </IconButton>
          </Box>
        </Box>
      )}

      <Box>
        <Typography
          id="zoom"
          variant="subtitle2"
          component="p"
          fontWeight={600}
          mb={0.5}
        >
          Zoom
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 1.25,
            alignItems: "center",
          }}
        >
          <IconButton
            disabled={minZoom === zoom}
            onClick={() => handleIncrement(false)}
            sx={{ color: "unset" }}
          >
            <ZoomOut />
          </IconButton>

          <Slider
            value={zoom}
            min={minZoom}
            max={maxZoom}
            step={stepZoom}
            size="small"
            aria-labelledby="zoom"
            onChange={handleZoom}
            sx={{
              "& .MuiSlider-rail": {
                backgroundColor: "#D9D9D9",
                height: "4px",
              },
            }}
          />

          <IconButton
            disabled={maxZoom === zoom}
            onClick={() => handleIncrement(true)}
            sx={{ color: "unset" }}
          >
            <ZoomIn />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          mx: 1,
          "& .MuiIcon-root": {
            fontSize: "1.725rem",
          },
        }}
      >
        <ActionButton size="small" onClick={flipHorizontal}>
          <Flip />
        </ActionButton>
        <ActionButton size="small" onClick={flipVertical}>
          <Flip sx={{ transform: "rotateZ(90deg)" }} />
        </ActionButton>
        <ActionButton size="small" onClick={rotateLeft}>
          <RotateLeft />
        </ActionButton>
        <ActionButton size="small" onClick={rotateRight}>
          <RotateRight />
        </ActionButton>
      </Box>

      <FormGroup
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          "& .MuiTypography-root": {
            fontSize: "0.75rem",
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={spreadWallpaper}
              onChange={handleSpreadWallaperChange}
              size="small"
            />
          }
          label="Použiť na viacero dverí"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={previewWallpaperSpread}
              onChange={handlePreviewWallpaperSpreadChange}
              size="small"
            />
          }
          label="Ukážka dverí"
          disabled={!spreadWallpaper}
        />
      </FormGroup>
    </Box>
  );
};

export default WallpaperTransforms;
